<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		tooltipMsg="pmanualforpacka"
		:showActionDelete="showDeleteButton"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
		@delete="openDeleteModal()"
	>
		<template #header>
			{{ $t('detailofpackage') }}
			<label>&nbsp;{{ $t('id') }}&nbsp;{{ model.ProductId }}</label>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import serviceEnums from '@/services/service/enums.service';
import http from '@/services/helpers/http';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';

import { model, fields } from './product.form-data';
import { handleTextboxVisibility } from './product.service';

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'Product',
			routeParam: 'ProductId',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		showDeleteButton() {
			return auth.IsAllowedForUser(enums.role.Admin);
		},
	},

	mounted() {
		const schemaUpdate = handleTextboxVisibility(this.CountryId);
		this.updateSchemaFields(schemaUpdate);
	},

	methods: {
		async loadResources() {
			const [reinvoices, thematicSections] = await Promise.all([
				http.get(`${this.controllerName}/GetListReInvoice`).then((res) => res.data),
				http.get(`ThematicSection/List`).then((res) => res.data),
			]);

			const countries = this.$store.state.country.data;

			this.updateSchemaFields({
				CountryId: {
					values: countries,
					onChanged: (model, newVal, oldVal, field) => {
						const schemaUpdate = handleTextboxVisibility(newVal);
						this.updateSchemaFields(schemaUpdate);
						this.updateFormModel({ TextSelector: newVal });
					},
				},
				TextSelector: {
					values: countries,
					onChanged: (model, newVal, oldVal, field) => {
						const schemaUpdate = handleTextboxVisibility(newVal);
						this.updateSchemaFields(schemaUpdate);
					},
				},
				ProductSet: { values: serviceEnums.getEnumForVFG('productSet') },
				ProductReinvoicingId: { values: reinvoices },
				TsectionId: { values: thematicSections },
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.ProductId, this.$modal, this.deleteModel);
		},

		transformDtoToModel(dtoModel) {
			dtoModel.TextSelector = dtoModel.CountryId;
			dtoModel.CurrencyId = dtoModel.CountryId;

			return dtoModel;
		},
	},
};
</script>