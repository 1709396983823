import enums from '@/services/helpers/enums';

export function handleTextboxVisibility(modelCountryId) {
	const schemaUpdate = {
		ProductNameSk: { visible: false },
		ProductNameCz: { visible: false },
		ProductNamePl: { visible: false },
		ProductNameEn: { visible: false },
		ProductNameDe: { visible: false },
		ProductNameHu: { visible: false },
		ProductNameRu: { visible: false },
		ProductNameRegistrationSk: { visible: false },
		ProductNameRegistrationCz: { visible: false },
		ProductNameRegistrationEn: { visible: false },
		ProductNameRegistrationPl: { visible: false },
		ProductNameRegistrationHu: { visible: false },
		ProductNameRegistrationRu: { visible: false },
		ProductNameRegistrationDe: { visible: false },
		ProductNameInvoiceSk: { visible: false },
		ProductNameInvoiceCz: { visible: false },
		ProductNameInvoiceEn: { visible: false },
		ProductNameInvoicePl: { visible: false },
		ProductNameInvoiceHu: { visible: false },
		ProductNameInvoiceRu: { visible: false },
		ProductNameInvoiceDe: { visible: false },
		ProductNoteSk: { visible: false },
		ProductNoteCz: { visible: false },
		ProductNoteEn: { visible: false },
		ProductNotePl: { visible: false },
		ProductNoteHu: { visible: false },
		ProductNoteRu: { visible: false },
		ProductNoteDe: { visible: false },
	};

	switch (modelCountryId) {
		case enums.country.CZ:
			schemaUpdate.ProductNameCz.visible = true;
			schemaUpdate.ProductNameRegistrationCz.visible = true;
			schemaUpdate.ProductNameInvoiceCz.visible = true;
			schemaUpdate.ProductNoteCz.visible = true;
			break;
		case enums.country.EN:
			schemaUpdate.ProductNameEn.visible = true;
			schemaUpdate.ProductNameRegistrationEn.visible = true;
			schemaUpdate.ProductNameInvoiceEn.visible = true;
			schemaUpdate.ProductNoteEn.visible = true;
			break;
		case enums.country.PL:
			schemaUpdate.ProductNamePl.visible = true;
			schemaUpdate.ProductNameRegistrationPl.visible = true;
			schemaUpdate.ProductNameInvoicePl.visible = true;
			schemaUpdate.ProductNotePl.visible = true;
			break;
		case enums.country.HU:
			schemaUpdate.ProductNameHu.visible = true;
			schemaUpdate.ProductNameRegistrationHu.visible = true;
			schemaUpdate.ProductNameInvoiceHu.visible = true;
			schemaUpdate.ProductNoteHu.visible = true;
			break;
		case enums.country.RU:
			schemaUpdate.ProductNameRu.visible = true;
			schemaUpdate.ProductNameRegistrationRu.visible = true;
			schemaUpdate.ProductNameInvoiceRu.visible = true;
			schemaUpdate.ProductNoteRu.visible = true;
			break;
		case enums.country.DE:
			schemaUpdate.ProductNameDe.visible = true;
			schemaUpdate.ProductNameRegistrationDe.visible = true;
			schemaUpdate.ProductNameInvoiceDe.visible = true;
			schemaUpdate.ProductNoteDe.visible = true;
			break;
		case enums.country.SK:
		default:
			schemaUpdate.ProductNameSk.visible = true;
			schemaUpdate.ProductNameRegistrationSk.visible = true;
			schemaUpdate.ProductNameInvoiceSk.visible = true;
			schemaUpdate.ProductNoteSk.visible = true;
			break;
	}

	return schemaUpdate;
}
