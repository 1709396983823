import vueFormGenerator from 'vue-form-generator';
import enums from '@/services/helpers/enums';

export const model = {
	ProductId: 0,
	ProductSet: 0,
	TextSelector: enums.country.SK,
	ProductIsMain: false,
	ProductNameSk: null,
	ProductNameCz: null,
	ProductNamePl: null,
	ProductNameDe: null,
	ProductNameEn: null,
	ProductNameHu: null,
	ProductNameRu: null,
	ProductNameRegistrationSk: null,
	ProductNameRegistrationCz: null,
	ProductNameRegistrationPl: null,
	ProductNameRegistrationDe: null,
	ProductNameRegistrationEn: null,
	ProductNameRegistrationHu: null,
	ProductNameRegistrationRu: null,
	ProductNameInvoiceSk: null,
	ProductNameInvoiceCz: null,
	ProductNameInvoicePl: null,
	ProductNameInvoiceDe: null,
	ProductNameInvoiceEn: null,
	ProductNameInvoiceHu: null,
	ProductNameInvoiceRu: null,
	ProductNoteSk: null,
	ProductNoteCz: null,
	ProductNotePl: null,
	ProductNoteDe: null,
	ProductNoteEn: null,
	ProductNoteHu: null,
	ProductNoteRu: null,
	ProductPrice: null,
	ProductPriceSale: null,
	ProductPriceAdmin: null,
	ProductIsEnabled: false,
	ProductMonths: 0,
	ProductReinvoicingId: 0,
	TsectionId: 0,
	ProductAllowRegistrationGratis: false,
	ProductAllowRegistration: false,
	ProductTsectionDateStart: null,
	ProductTsectionDateEnd: null,
	CountryId: null,
	CurrencyId: 0,
};

export const fields = [
	{
		fields: [
			{
				type: 'select',
				model: 'ProductSet',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'id',
					name: 'name',
				},
				i18n: {
					label: 'series3011',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'radios',
				model: 'TextSelector',
				values: [],
				radiosOptions: {
					value: 'Id',
					name: 'LanguageCode',
				},
				i18n: {
					label: 'languagetransla',
				},
				styleClasses: 'inline-radio-list',
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameSk',
				visible: false,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'namesk3012',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameCz',
				visible: false,
				i18n: {
					label: 'namecz3016',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNamePl',
				visible: false,
				i18n: {
					label: 'namepl3020',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameEn',
				visible: false,
				i18n: {
					label: 'nameen3024',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameDe',
				visible: false,
				i18n: {
					label: 'namede3028',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameHu',
				visible: false,
				i18n: {
					label: 'namehu3032',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRu',
				visible: false,
				i18n: {
					label: 'Názov pre registráciu (RU):',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRegistrationSk',
				visible: false,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'nameforregistra',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRegistrationCz',
				visible: false,
				i18n: {
					label: 'nameforregistra3017',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRegistrationPl',
				visible: false,
				i18n: {
					label: 'nameforregistra3021',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRegistrationEn',
				visible: false,
				i18n: {
					label: 'nameforregistra3025',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRegistrationDe',
				visible: false,
				i18n: {
					label: 'nameforregistra3029',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRegistrationHu',
				visible: false,
				i18n: {
					label: 'nameforregistra3033',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameRegistrationRu',
				visible: false,
				i18n: {
					label: 'Poznámka pre registráciu (RU):',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameInvoiceSk',
				visible: false,
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'nameforinvoices',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameInvoiceCz',
				visible: false,
				i18n: {
					label: 'nameforinvoicec',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameInvoicePl',
				visible: false,
				i18n: {
					label: 'nameforinvoicep',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameInvoiceEn',
				visible: false,
				i18n: {
					label: 'nameforinvoicee',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameInvoiceDe',
				visible: false,
				i18n: {
					label: 'nameforinvoiced',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameInvoiceHu',
				visible: false,
				i18n: {
					label: 'nameforinvoiceh',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNameInvoiceRu',
				visible: false,
				i18n: {
					label: 'Názov pre faktúru (RU):',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNoteSk',
				visible: false,
				i18n: {
					label: 'noteforregistra',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNoteCz',
				visible: false,
				i18n: {
					label: 'noteforregistra3019',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNotePl',
				visible: false,
				i18n: {
					label: 'noteforregistra3023',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNoteEn',
				visible: false,
				i18n: {
					label: 'noteforregistra3027',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNoteDe',
				visible: false,
				i18n: {
					label: 'noteforregistra3031',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNoteHu',
				visible: false,
				i18n: {
					label: 'noteforregistra3035',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'ProductNoteRu',
				visible: false,
				i18n: {
					label: 'Poznámka pre registráciu (RU):',
				},
			},
			{
				type: 'select',
				inputType: 'text',
				model: 'CountryId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'Id',
					name: 'Name',
				},
				i18n: {
					label: 'country:',
					selectOptions: {
						noneSelectedText: 'select',
					},
				},
				styleClasses: 'half-width',
			},
			{
				type: 'formated-input',
				model: 'ProductPrice',
				required: true,
				validator: vueFormGenerator.validators.number,
				i18n: {
					label: 'listprice3036',
				},
			},
			{
				type: 'formated-input',
				model: 'ProductPriceSale',
				required: true,
				validator: vueFormGenerator.validators.number,
				i18n: {
					label: 'discountedprice3037',
				},
			},
			{
				type: 'formated-input',
				model: 'ProductPriceAdmin',
				required: true,
				validator: vueFormGenerator.validators.number,
				i18n: {
					label: 'priceinlandlord3038',
				},
			},
			{
				type: 'select',
				model: 'TsectionId',
				required: true,
				validator: vueFormGenerator.validators.integer,
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'TsectionId',
					name: 'TsectionNameSk',
				},
				i18n: {
					label: 'localen',
				},
				visible: function(model) {
					return model.ProductSet == enums.productSet.AdditionalSectionSet;
				},
			},
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'ProductTsectionDateStart',
				showtime: false,
				autoselect: true,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'ProductTsectionDateStart - slovko',
					help: '',
				},
				visible: function(model) {
					return model.ProductSet == enums.productSet.AdditionalSectionSet;
				},
			},
			{
				type: 'single-datepicker',
				language: 'sk',
				model: 'ProductTsectionDateEnd',
				showtime: false,
				autoselect: true,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'ProductTsectionDateEnd - slovko',
					help: '',
				},
				visible: function(model) {
					return model.ProductSet == enums.productSet.AdditionalSectionSet;
				},
			},
			{
				type: 'input',
				inputType: 'number',
				model: 'ProductMonths',
				validator: vueFormGenerator.validators.number,
				i18n: {
					label: 'numberofmonths',
				},
				styleClasses: 'half-width',
			},
			{
				type: 'select',
				model: 'ProductReinvoicingId',
				values: [],
				selectOptions: {
					noneSelectedText: '',
					value: 'ProductId',
					name: 'ProductNameSk',
				},
				i18n: {
					label: 'reinvoicingpack',
				},
				styleClasses: 'half-width',
				visible: function(model) {
					return model.ProductSet == enums.productSet.StandardSet || model.ProductSet == enums.productSet.TopSet;
				},
			},
			{
				type: 'switch',
				model: 'ProductIsEnabled',
				i18n: {
					label: 'active:',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'ProductIsMain',
				i18n: {
					label: 'main3039',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'switch',
				model: 'ProductAllowRegistration',
				i18n: {
					label: 'availableforreg',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
				visible: function(model) {
					return model.ProductSet == enums.productSet.StandardSet || model.ProductSet == enums.productSet.TopSet;
				},
			},
		],
	},
];
